import { useEffect, useRef, useState } from 'react'
import Grid from '@mui/material/Grid'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import Box from '@mui/material/Box'
import { Container, FormControlLabel, FormHelperText, Stack, Typography, Chip } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import scheduleApi from 'src/api/scheduleApi'
import { formatTargetDatePeriod2 } from '../../../helpers/formatDate'
import { at, debounce } from 'lodash'
import userApi from 'src/api/userApi'
import { useSnackBar } from 'src/components/Common/SnackBarContext'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded'
import PeopleAltSharpIcon from '@mui/icons-material/PeopleAltSharp'
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp'
import PersonAddAltSharpIcon from '@mui/icons-material/PersonAddAltSharp'
import ComponentStepper from '../../adjustment/components/Stepper'
import { TextInput } from 'src/components/TextInput'
import { labelOfInput, labelOfInputAlign } from 'src/theme'
import ButtonCustom from 'src/components/Common/ButtonCustom'
import ModalConfirm from 'src/components/Common/ModalConfirm'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { selectFilterSchedules, topActions } from 'src/pages/Top/topSlice'
import { Status } from 'src/models'
import { CheckBoxCustom } from '../components/CheckBox'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { replaceGaroonName } from '../../../helpers/helpers'
import useWindowDimensions from 'src/hook/useWindowDimensions'
import { createStyles, makeStyles, styled } from '@mui/styles';
import { forEach } from 'lodash';
import { selectCurrentUser } from 'src/pages/Auth/authSlice'
import adjustmentApi from 'src/api/adjustmentApi'

const AdjustmentStep3 = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { sid } = useParams()
    const { height, width } = useWindowDimensions();

    const filterSchedules = useAppSelector(selectFilterSchedules)

    const { showSnackBar } = useSnackBar()
    const [invitees, setInvitees] = useState<any[]>([
        {
            id: 0,
            name: '',
            email: '',
            isValidEmail: true,
            isValidName: true,
            isOldEmail: false,
        },
    ])
    const [scheduleData, setScheduleData] = useState<any>(null)
    const [dataUpdateInvitees, setDataUpdateInvitees] = useState<any>({
        id: Number(sid),
        participants: [],
        email_subject: '',
        email_body: '',
    })

    const [isFocusedName, setIsFocusedName] = useState(-1)
    const [isFocusedEmail, setIsFocusedEmail] = useState(-1)

    const [externalUsers, setExternalUsers] = useState([])
    const currentUser = useAppSelector(selectCurrentUser)
    const [openModalConfirm, setOpenModalConfirm] = useState(false)
    const [typeModal, setTypeModal] = useState('confirm')
    const [isLoading, setIsLoading] = useState(false)
    const [validateSubject, setValidateSubject] = useState<any>({
        isValidMaxLength: true,
        isValidRequired: true,
    })
    const [validateBody, setValidateBody] = useState<any>({
        isValidMaxLength: true,
        isValidRequired: true,
    })
    const [titleModalConfirm, setTitleModalConfirm] = useState('')
    const [messageModalConfirm, setMessageModalConfirm] = useState('')

    const duplicateEmails = useRef(false)
    const [checkEventMenu, setCheckEventMenu] = useState(true)
    const [isCCChecked, setIsCCChecked] = useState(false)
    const [scheduleTags, setScheduleTags] = useState<any>([])
    const location = useLocation();
    const dataLocation = location.state?.dataLocation; 

    const useStyles = makeStyles(theme => ({
        inputRoot: {
          '& .MuiOutlinedInput-input.Mui-disabled': {
            WebkitTextFillColor: '#070707',
            cursor: "not-allowed"
          },
        },
      }));
    const classes = useStyles();

    const handleOpenModal = (type: string, title: string, message: string) => {
        setTypeModal(type)
        setTitleModalConfirm(title)
        setMessageModalConfirm(message)
        setOpenModalConfirm(true)
    }

    const getListScheduleTags = () => {
        adjustmentApi.getListScheduleTags().then((res: any) => {
            if (res.success) {
                setScheduleTags(res.data);
            }
            return;
        })
        .catch((err) => {
            return;
        })
    }

    useEffect(() => {
        getScheduleData()
        getExternalUsers()
        getSuggestedInvitee()
        getListScheduleTags()
    }, [])

    const isEditting = () => {
        return sid && currentUser?.is_employee && scheduleData?.status === "ADJUSTING" && dataLocation === undefined;
    }

    const getScheduleData = () => {
        scheduleApi
            .getById({
                id: Number(sid),
            })
            .then((res) => {
                setScheduleData(res.data)
                setDataUpdateInvitees({
                    ...dataUpdateInvitees,
                    email_subject: `【GLOBAL PRODUCE】${res?.data?.subject || ''}／スケジュールのご確認`,
                    email_body: `{name} 様<br><br>`
                        + `いつもお世話になっております。<br>`
                        + `グローバルプロデュースの${replaceGaroonName(res?.data?.user, width, true)}です。<br><br>`
                        + `下記リンクより、参加可能な日時をお知らせください。<br>`
                        + `{url}<br><br>`
                        + `大変恐縮ですが、<strong>2営業日内</strong>にご返信頂けますと幸いです。<br>`
                        + `どうぞよろしくお願い致します。<br><br>`
                        + `----------------------------------------------------<br>`
                        + `株式会社グローバルプロデュース<br>${replaceGaroonName(res?.data?.user, width, true)}<br>`
                        + `〒150-0001    東京都渋谷区神宮前5-52-2<br>`
                        + `青山オーバルビル13F</br>`
                        + `OFFICE／03-5738-2117 （FAX2118）<br>`
                        + `https://www.global-produce.jp/`,
                })
                if (!res.data.event_menu || res.data.event_menu.trim().length === 0) {
                    setCheckEventMenu(false)
                }
                const external = res.data.participant.filter((el: any) => !el.user.is_employee)
                if (external.length > 0) {
                    let currentInvitees: any = []
                    for (let i = 0; i < external.length; i++) {
                        const el = external[i]
                        currentInvitees.push({
                            id: i,
                            name: el.user.name,
                            email: el.user.email,
                            isValidEmail: true,
                            isValidName: true,
                            isOldEmail: true,
                        })
                    }
                    setInvitees(currentInvitees)
                }
            })
            .catch((err) => {
                console.log('Create adjustment step 3 -> scheduleApi.getById failed: ', err)
            })
    }

    const [suggestedInvitee, setSuggestedInvitee] = useState<any[]>([])
    const getSuggestedInvitee = () => {
        userApi
            .getSuggestedInvitee()
            .then((res) => {
                setSuggestedInvitee(res.data)
            })
            .catch((err) => {
                console.log('Create adjustment step 3 -> userApi.getSuggestedInvitee failed: ', err)
            })
    }

    const getExternalUsers = () => {
        userApi
            .getExternalUsers()
            .then((res) => {
                setExternalUsers(res.data)
            })
            .catch((err) => {
                console.log('Create adjustment step 3 -> userApi.getExternalUsers failed: ', err)
            })
    }

    const handleAddRowInvitee = () => {
        setInvitees((prev) => [
            ...prev,
            {
                id: invitees.length,
                name: '',
                email: '',
                isValidEmail: true,
                isValidName: true,
                isOldEmail: false
            },
        ])
    }

    const handleRemoveRowInvitee = (index: number) => {
        let data = [...invitees]
        data.splice(index, 1)
        setInvitees(data)
    }

    const onChangeFullName = (event: any, index: number) => {
        const fullName = event.target.value
        let data = [...invitees]
        data[index].name = fullName
        data[index].isValidName = fullName.length <= 50 && fullName.length > 0

        if (data[index].name.length === 0 && data[index].email.length === 0) {
            data[index].isValidEmail = true
            data[index].isValidName = true
            data[index].isOldEmail = false
        }
        setInvitees(data)
    }

    const onChangeEmail = (event: any, index: number) => {
        const email = event.target.value
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        let data = [...invitees]
        data[index].email = email
        if (email.length == 0) {
            data[index].isValidEmail = false
        } else {
            data[index].isValidEmail = emailRegex.test(email)
        }

        if (data[index].name.length === 0 && data[index].email.length === 0) {
            data[index].isValidEmail = true
            data[index].isValidName = true
        }

        setInvitees(data)
    }

    const onChangeEmailSubject = debounce((e) => {
        if (e.target.value.length > 100) {
            setValidateSubject({
                ...validateSubject,
                isValidMaxLength: false,
                isValidRequired: true,
            })
        } else if (e.target.value.length === 0) {
            setValidateSubject({
                ...validateSubject,
                isValidMaxLength: true,
                isValidRequired: false,
            })
        } else {
            setValidateSubject({
                ...validateSubject,
                isValidMaxLength: true,
                isValidRequired: true,
            })
        }
        setDataUpdateInvitees({
            ...dataUpdateInvitees,
            email_subject: e.target.value,
        })
    }, 500)

    const onChangeEmailBody = debounce((data: any) => {
        if (data.length > 5000) {
            setValidateBody({
                ...validateBody,
                isValidMaxLength: false,
                isValidRequired: true,
            })
        } else if (data.length === 0) {
            setValidateBody({
                ...validateBody,
                isValidMaxLength: true,
                isValidRequired: false,
            })
        } else {
            setValidateBody({
                ...validateBody,
                isValidMaxLength: true,
                isValidRequired: true,
            })
        }
        setDataUpdateInvitees({
            ...dataUpdateInvitees,
            email_body: data,
        })
    }, 500)

    const handleSubmit = () => {
        if (validateForm()) {
            const participants = invitees
                .filter((el: any) => el.name.trim() !== '' && el.email.trim() !== '')
                .map((el: any) => ({
                    name: el.name,
                    email: el.email,
                }))

            if (isEditting()) {
                handleOpenModal('confirm', '確認', ' 新規で追加した招待者のみにメールが送信されます。')
            } else if (participants.length === 0) {
                handleOpenModal('confirm', '確認', ' 招待者が指定されていません。このまま続けてもよろしいですか？')
            } else {
                submit()
            }
        }
    }

    const validateForm = () => {
        if (
            invitees.some(
                (el: any) =>
                    (el.name.length === 0 && el.email.length > 0) ||
                    (el.name.length > 0 && el.email.length === 0) ||
                    !el.isValidEmail ||
                    !el.isValidName,
            ) ||
            duplicateEmails.current
        ) {
            showSnackBar(true, '入力エラーがあります。修正して再度実行ボタンをクリックしてください。', 'error')
            return false
        }

        if (invitees.some((el: any) => el.email.length > 0)) {
            let text = ''
            if (dataUpdateInvitees.email_subject.length === 0) {
                setValidateSubject({
                    ...validateSubject,
                    isValidRequired: false,
                })
                text += '「件名」'
            }

            if (dataUpdateInvitees.email_body.length === 0) {
                setValidateBody({
                    ...validateBody,
                    isValidRequired: false,
                })
                text += '「本文」'
            }

            if (text !== '') {
                showSnackBar(true, '入力エラーがあります。修正して再度実行ボタンをクリックしてください。', 'error')
                return false
            }
        } else {
            setValidateSubject({
                ...validateSubject,
                isValidRequired: true,
            })
            setValidateBody({
                ...validateBody,
                isValidRequired: true,
            })
        }

        if (!validateSubject.isValidMaxLength || !validateBody.isValidMaxLength) {
            showSnackBar(true, '入力エラーがあります。修正して再度実行ボタンをクリックしてください。', 'error')
            return false
        }

        return true
    }

    const submit = () => {
        setIsLoading(true)
        const participants = invitees
            .filter((el: any) => el.name.trim() !== '' && el.email.trim() !== '')
            .map((el: any) => ({
                name: el.name,
                email: el.email,
            }))

        scheduleApi
            .updateInvitees({
                ...dataUpdateInvitees,
                participants: participants,
                isCCToInternalUser: isCCChecked,
                attachment_file: filesData,
            })
            .then((res: any) => {
                if (res?.success) {
                    scheduleApi
                        .getById({
                            id: scheduleData.id,
                        })
                        .then((res) => {
                            dispatch(topActions.setScheduleNewUpdate(res.data))
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                    if (isEditting()) {
                        showSnackBar(true, `予定「${scheduleData.subject}」を修正しました。`, 'success')
                    } else{
                        showSnackBar(true, `予定「${scheduleData.subject}」を登録しました。`, 'success')
                    }
                    setIsLoading(false)
                    navigate('/schedule-list', {state: {admin_id: filterSchedules.admin_id, participants: filterSchedules.participants, status: Status.ADJUSTING}})
                } else {
                    setIsLoading(false)
                    showSnackBar(true, 'メールの送信に失敗しました', 'error')
                }
            })
            .catch((err) => {
                showSnackBar(true, 'メールの送信に失敗しました', 'error')
                setIsLoading(false)
            })
    }

    const handleClickUser = (el: any, index: number) => {
        let currentInvitees = [...invitees]
        currentInvitees[index].name = el.name
        currentInvitees[index].email = el.email
        currentInvitees[index].isValidName = true
        currentInvitees[index].isValidEmail = true
        currentInvitees[index].isOldEmail = false
        setInvitees(currentInvitees)
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const fileMimeType = [
        'image/jpeg',
        'image/png',
        'image/jpg',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    ]

    const [fileAttachment, setFileAttachment] = useState<any[]>([]);
    const [filesData, setFilesData] = useState<any[]>([]);
    const useRefFileInput = useRef<any>(null);
    const [errorFile, setErrorFile] = useState('');
    const handleFileAttachment = (event: any) => {
        const {files} = event.target;

        const validImageFiles = [];
        let isValidate = false;
        setErrorFile('');
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (!fileMimeType.includes(file.type)) {
                // reset file input
                if(useRefFileInput && useRefFileInput.current) {
                    useRefFileInput.current.value = '';
                }
                setErrorFile('ファイル形式が正しくありません');
                isValidate = true;
                return;
            }
            if (file.size > 10485760) {
                // reset file input
                if(useRefFileInput && useRefFileInput.current) {
                    useRefFileInput.current.value = '';
                }
                setErrorFile('10MB以下のファイルを添付してください。');
                isValidate = true;
                return;
            }

            // if (filesData && filesData.length > 0 && filesData.some((e: any) => e.name === file.name)) {
            //     // reset file input
            //     if(useRefFileInput && useRefFileInput.current) {
            //         useRefFileInput.current.value = '';
            //     }
            //     showSnackBar(true, 'ファイルが重複しています', 'error');
            //     isValidate = true;
            //     return;
            // }

            validImageFiles.push(file);
        }

        if (!isValidate && validImageFiles.length > 0) {
            setFileAttachment(validImageFiles);
        }
    }

    useEffect(() => {
        // reset file input
        if(useRefFileInput && useRefFileInput.current) {
            useRefFileInput.current.value = '';
        }

        let fileReaders: any[] = [];
        let isCancel = false;
        if (fileAttachment.length > 0) {
            forEach(fileAttachment, (file: any) => {
                let fileReader = new FileReader();
                fileReaders.push(fileReader);
                fileReader.onload = (e: any) => {
                    const { result } = e.target;
                    if (result && !isCancel) {
                        setFilesData((prev: any) => {
                            return [
                                ...prev,
                                {
                                    name: file.name,
                                    size: file.size,
                                    type: file.type,
                                    data: result,
                                },
                            ];
                        });    
                    }
                };
                fileReader.readAsDataURL(file);
            })
        }

        return () => {
            isCancel = true;
            fileReaders.forEach(fileReader => {
                if (fileReader.readyState === 1) {
                    fileReader.abort()
                }
            })
        }
    }, [fileAttachment]);

    const handleDeleteFile = (file: any, index: number) => () => {
        setFilesData((prev: any) => {
            const newFilesData = [...prev];
            newFilesData.splice(index, 1);
            return newFilesData;
        });
    };

    const handleBackToStep2 = () => {
        if (!isEditting()) {
            navigate(`/adjustment/step2/${sid}`, { state: { dataLocation: {is_creating: true} } });
        } else {
            navigate(`/adjustment/step2/${sid}`);
        }
    }

    if (scheduleData == null) {
        return
    }

    return (
        <Container sx={{ p: '0 !important'}}>
            <Grid container spacing={4}>
                <Grid item xs={12} sx={{ mt: '14px', mb: '28px' }}>
                    <ComponentStepper activeStep={2} />
                </Grid>
            </Grid>
            <Grid container sx={styles.formBackground}>
                <Grid item xs={12}>
                    <Box sx={styles.adjustmentTitle}>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                            gap={1}
                        >
                            <Stack direction={'row'} spacing={{ xs: 1, xsm: 2}}>
                                <Stack 
                                    direction={'row'} 
                                    spacing={{ xs: 1, xsm: 2}}
                                    sx={{
                                        width: {
                                            xs: "130px",
                                            xsm: "150px"
                                        },
                                    }}
                                >
                                    <InsertDriveFileOutlinedIcon sx={styles.boxAddInviteeIcon}/>
                                    <Typography sx={styles.label}>予定タイトル</Typography>
                                </Stack>
                                <Stack
                                    direction={'row'}
                                    spacing={{ xs: 1, xsm: 2}}
                                    sx={{
                                        width: {
                                            xs: 'calc(100% - 130px)',
                                            xsm: 'calc(100% - 130px)',
                                        },
                                    }}
                                >
                                    {
                                        (() => {
                                            if (checkEventMenu) {
                                                let tag = scheduleTags.find((element: any) => element?.name == scheduleData?.event_menu)
                                                return (
                                                    <Box 
                                                        sx={[
                                                            styles.boxScheduleEventMenu, 
                                                            {
                                                                height: '21px',
                                                                backgroundColor: tag && tag?.color ? tag?.color : '#9F7AEA',
                                                            }
                                                        ]}
                                                    >
                                                        <Typography sx={styles.boxScheduleEventMenuText}>
                                                            {scheduleData.event_menu}
                                                        </Typography>
                                                    </Box>
                                                )
                                            } else {
                                                return ''
                                            }
                                        })()
                                    }
                                    <Typography
                                        sx={{ 
                                            lineHeight: 1.4, 
                                            fontSize: '15px',
                                            whiteSpace: 'normal',
                                        }}
                                    >
                                        {scheduleData.subject}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack direction={'row'} spacing={{ xs: 1, sm: 2}}>
                                <Stack 
                                    direction={'row'} 
                                    spacing={{ xs: 1, xsm: 2}}
                                    sx={{
                                        width: {
                                            xs: "130px",
                                            xsm: "150px"
                                        },
                                    }}
                                >
                                    <EventNoteRoundedIcon sx={styles.boxAddInviteeIcon}/>
                                    <Typography sx={styles.label}>調整期間</Typography>
                                </Stack>
                                <Stack 
                                    direction={'row'}
                                    sx={{
                                        width: {
                                            xs: 'calc(100% - 130px)',
                                            xsm: 'calc(100% - 130px)',
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            lineHeight: 1.4,
                                            fontSize: '15px',
                                            whiteSpace: 'normal'
                                        }}
                                    >
                                        {formatTargetDatePeriod2(
                                            scheduleData.adjustment_start_date,
                                            scheduleData.adjustment_end_date,
                                        )}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack direction={'row'} spacing={{ xs: 1, sm: 2}}>
                                <Stack 
                                    direction={'row'} 
                                    spacing={{ xs: 1, xsm: 2}}
                                    sx={{
                                        width: {
                                            xs: "130px",
                                            xsm: "150px"
                                        },
                                    }}
                                >
                                    <PeopleAltSharpIcon sx={styles.boxAddInviteeIcon}/>
                                    <Typography sx={styles.label}>参加者</Typography>
                                </Stack>
                                <Stack 
                                    direction={'row'}
                                    sx={{
                                        width: {
                                            xs: 'calc(100% - 130px)',
                                            xsm: 'calc(100% - 130px)',
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            lineHeight: 1.4, 
                                            fontSize: '15px',
                                            whiteSpace: 'normal'
                                        }}
                                    >
                                        {scheduleData.participant.map((e: any) => {
                                            return replaceGaroonName(e.user, width);
                                        }).join('、')}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Box>
                        <Grid item xs={12} sx={styles.invitees}>
                            <Typography sx={[styles.label, { mb: 1, width: '100%' }]}>招待者</Typography>
                            {(duplicateEmails.current = false)}
                            {invitees.map((invitee, index) => {
                                // let existInvitee = inviteesRoot.filter((inv: any) => invitee.email == inv.email && invitee.isOldEmail)
                                // let disable = false;
                                // if (existInvitee.length > 0) {
                                //     disable = true
                                // }
                                return (
                                    <Grid container key={invitee.id} item xs={12} sx={styles.invitee}>
                                        <Grid item xs={5} sm={4} md={3} sx={{ position: 'relative', paddingLeft: 0, cursor: 'not-allowed', }}>
                                            <TextInput
                                                style={invitee.isOldEmail && isEditting() ? {
                                                    backgroundColor: "#eaeaea",
                                                    borderRadius: '15px',
                                                } : {}}
                                                disabled={invitee.isOldEmail && isEditting()}
                                                value={invitee.name}
                                                onChange={(event: any) => onChangeFullName(event, index)}
                                                error={!invitee.isValidName}
                                                placeholder="例）山田 太郎"
                                                onClick={() => {
                                                    setIsFocusedName(isFocusedName === index ? -1 : index)
                                                    setIsFocusedEmail(-1)
                                                }}
                                                onBlur={() => setIsFocusedName(-1)}
                                                autoComplete="off"
                                                classes={{root: classes.inputRoot}}
                                            />
                                            <FormHelperText error={!invitee.isValidName} sx={{ whiteSpace: 'wrap' }}>
                                                {!invitee.isValidName &&
                                                    '「招待者」の長さは 0 ～ 50 文字にする必要があります'}
                                            </FormHelperText>
                                            {
                                                (() => {
                                                    if(isFocusedName === index) {
                                                        if(invitee.name.length == 0 && suggestedInvitee.length > 0) {
                                                            return (
                                                                <Box sx={styles.boxSearchName}>
                                                                    {suggestedInvitee.map((el: any) => {
                                                                        return (
                                                                            <Box
                                                                                sx={{
                                                                                    p: '8px',
                                                                                    '&:hover': {
                                                                                        bgcolor: '#ffff00',
                                                                                        color: '#000000',
                                                                                    },
                                                                                }}
                                                                                key={el?.invitee?.id}
                                                                                className={'box-name'}
                                                                                onMouseDown={() => handleClickUser(el?.invitee, index)}
                                                                            >
                                                                                {el?.invitee?.name}
                                                                            </Box>
                                                                        )
                                                                    })}
                                                                </Box>
                                                            )
                                                        } else if (
                                                            invitee.name.length > 0 &&
                                                            externalUsers.filter((el: any) =>
                                                                el.name.toLowerCase().includes(invitee.name.toLowerCase()),
                                                            ).length > 0 &&
                                                            externalUsers.length > 0
                                                        ) {
                                                            return (
                                                                <Box sx={styles.boxSearchName}>
                                                                    {externalUsers
                                                                        .filter((el: any) =>
                                                                            el.name
                                                                                .toLowerCase()
                                                                                .includes(invitee.name.toLowerCase()),
                                                                        )
                                                                        .map((el: any) => {
                                                                            return (
                                                                                <Box
                                                                                    sx={{
                                                                                        p: '8px',
                                                                                        '&:hover': {
                                                                                            bgcolor: '#ffff00',
                                                                                            color: '#000000',
                                                                                        },
                                                                                    }}
                                                                                    key={el.id}
                                                                                    className={'box-name'}
                                                                                    onMouseDown={() => handleClickUser(el, index)}
                                                                                >
                                                                                    {el.name}
                                                                                </Box>
                                                                            )
                                                                        })}
                                                                </Box>
                                                            )

                                                        }
                                                    }
                                                })()
                                            }
                                        </Grid>
                                        <Grid item xs={index == 0 ? 7 : 6} sm={6} md={8} sx={{ position: 'relative', paddingLeft:{xs: '8px', sm: '16px'} }}>
                                            <TextInput
                                                style={invitee.isOldEmail && isEditting() ? {backgroundColor: "#eaeaea", borderRadius: '15px',  WebkitTextFillColor: '#070707'} : {}}
                                                disabled={invitee.isOldEmail && isEditting()}
                                                value={invitee.email}
                                                placeholder="例）taro.yamada@example.com"
                                                onChange={(event: any) => {
                                                    onChangeEmail(event, index)
                                                }}
                                                error={!invitee.isValidEmail}
                                                onClick={() => {
                                                    setIsFocusedEmail(index === isFocusedEmail ? -1 : index)
                                                    setIsFocusedName(-1)
                                                }}
                                                onBlur={() => setIsFocusedEmail(-1)}
                                                autoComplete="off"
                                                classes={{root: classes.inputRoot}}
                                            />
                                            <FormHelperText error={!invitee.isValidEmail} sx={{ ml: '7px' }}>
                                                {!invitee.isValidEmail && '有効なメールアドレスを入力してください'}
                                            </FormHelperText>

                                            {invitees.some(
                                                (e: any) =>
                                                    e.id !== invitee.id &&
                                                    e.email.length > 0 &&
                                                    e.email === invitee.email,
                                            ) && (
                                                <>
                                                    {(duplicateEmails.current = true)}
                                                    <FormHelperText error>
                                                        重複したメールアドレスを入力しないでください
                                                    </FormHelperText>
                                                </>
                                            )}
                                            {
                                                (() => {
                                                    if (isFocusedEmail === index) {
                                                        if(invitee.email.length == 0 && suggestedInvitee.length > 0) {
                                                            return (
                                                                <Box sx={styles.boxSearchEmail}>
                                                                    {suggestedInvitee.map((el: any) => {
                                                                        return (
                                                                            <Box
                                                                                sx={{
                                                                                    p: '8px',
                                                                                    '&:hover': {
                                                                                        bgcolor: '#ffff00',
                                                                                        color: '#000000',
                                                                                    },
                                                                                }}
                                                                                key={el?.invitee?.id}
                                                                                onMouseDown={() => handleClickUser(el?.invitee, index)}
                                                                            >
                                                                                {el?.invitee?.email}
                                                                            </Box>
                                                                        )
                                                                    })}
                                                                </Box>
                                                            )
                                                        } else if (
                                                            invitee?.email?.length > 0 &&
                                                            externalUsers.filter((el: any) =>
                                                                el?.email?.trim().toLowerCase().includes(invitee?.email?.trim().toLowerCase()),
                                                            ).length > 0 &&
                                                            externalUsers.length > 0
                                                        ) {
                                                            return (
                                                                <Box sx={styles.boxSearchEmail}>
                                                                    {externalUsers.filter((el: any) =>
                                                                        el?.email?.trim().toLowerCase().includes(invitee?.email?.trim().toLowerCase()),
                                                                    ).map((el: any) => {
                                                                        return (
                                                                            <Box
                                                                                sx={{
                                                                                    p: '8px',
                                                                                    '&:hover': {
                                                                                        bgcolor: '#ffff00',
                                                                                        color: '#000000',
                                                                                    },
                                                                                }}
                                                                                key={el.id}
                                                                                onMouseDown={() => handleClickUser(el, index)}
                                                                            >
                                                                                {el.email}
                                                                            </Box>
                                                                        )
                                                                    })}
                                                                </Box>
                                                            )
                                                        }
                                                    }
                                                })()
                                            }
                                        </Grid>
                                        {
                                            index > 0 && !(invitee.isOldEmail && isEditting())&& (
                                                <Grid item xs={1} sx={{paddingLeft:{xs: 1, sm: 2}}}>
                                                    <RemoveCircleSharpIcon
                                                        sx={styles.iconRemove}
                                                        onClick={() => {
                                                            handleRemoveRowInvitee(index)
                                                        }}
                                                    />
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                )
                            })}

                            <Grid sx={styles.boxAddInvitee} onClick={handleAddRowInvitee}>
                                <PersonAddAltSharpIcon sx={{ fontSize: '20px' }} />
                                <Typography sx={styles.labelAddInvitee}>招待者を追加する</Typography>
                            </Grid>
                        </Grid>

                        <Grid sx={styles.boxAddInvitee}>
                            <FormControlLabel
                                control={
                                    <CheckBoxCustom
                                        checked={isCCChecked}
                                        onChange={() => setIsCCChecked(!isCCChecked)}
                                        sx={styles.checkBox}
                                        disabled={isEditting()}
                                    />
                                }
                                sx={[styles.formCheckBox, isEditting() && {cursor: "not-allowed !important", WebkitTextFillColor: '#070707'}]}
                                label="参加者にもCCで送付する"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Stack spacing={1} sx={{ mt: '30px', position: 'relative' }}>
                                <Typography sx={styles.label}>件名</Typography>
                                <TextInput
                                    defaultValue={dataUpdateInvitees.email_subject}
                                    error={!validateSubject.isValidMaxLength || !validateSubject.isValidRequired}
                                    onChange={onChangeEmailSubject}
                                />
                                <FormHelperText
                                    error={!validateSubject.isValidMaxLength || !validateSubject.isValidRequired}
                                    sx={{ position: 'absolute', bottom: '-20px' }}
                                >
                                    {!validateSubject.isValidMaxLength &&
                                        '「件名」の長さは100文字以内である必要があります'}
                                    {!validateSubject.isValidRequired && '「件名」は必須です'}
                                </FormHelperText>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sx={{ mt: '20px', position: 'relative' }}>
                            <Stack spacing={1}>
                                <Typography sx={styles.label}>本文</Typography>
                                <Typography 
                                    component={'div'}
                                    sx={[{
                                        border: '1px solid',
                                        borderColor: !validateBody.isValidMaxLength || !validateBody.isValidRequired ? 'red' : '#666',
                                        borderRadius: '15px',
                                        fontSize: '15px',
                                    }]}
                                >
                                    <CKEditor
                                        editor={DecoupledEditor}
                                        data={dataUpdateInvitees.email_body}
                                        config={{
                                            removePlugins: ['Title'],
                                            toolbar: [ 'undo', 'redo', '|', 'bold', 'fontColor' ],
                                            placeholder: '',
                                            translations: {
                                                '': {
                                                    dictionary: {
                                                        'Remove color': '色を削除',
                                                        'Color picker': 'その他の色',
                                                        'Document colors': '選択された色',
                                                    },
                                                }
                                            }
                                        }}
                                        onChange={(event: any, editor: any) => {
                                            const data = editor.getData()
                                            onChangeEmailBody(data)
                                        }}
                                        onReady={(editor: any) => {
                                            editor?.ui?.getEditableElement()?.parentElement?.insertBefore(
                                                editor.ui.view.toolbar.element as Node,
                                                editor.ui.getEditableElement() as Node
                                            );
                                            editor.editing.view.change((writer: any) => {
                                                writer.setStyle({
                                                    height: '300px',
                                                }, editor.editing.view.document.getRoot())
                                            })
                                        }}
                                    />
                                </Typography>
                                <FormHelperText
                                    sx={{
                                        position: 'absolute',
                                        bottom: '-20px',
                                    }}
                                    error={!validateBody.isValidMaxLength || !validateBody.isValidRequired}
                                >
                                    {!validateBody.isValidMaxLength &&
                                        '「本文」の長さは5000文字以内である必要があります'}
                                    {!validateBody.isValidRequired && '「本文」は必須です'}
                                </FormHelperText>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sx={{ mt: '20px', position: 'relative',  mb: 5 }}>
                            <Stack 
                                direction={{
                                    xs: 'column',
                                    sm: 'row'
                                }} 
                                spacing={1} 
                                alignItems={'start'}
                            >
                                <Stack direction={'row'} spacing={1}>
                                    <Typography
                                        component="label"
                                        sx={{
                                            color: '#1976d2',
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                            fontSize: '15px',
                                            minWidth: '140px',
                                            padding: '4px 0 4px 0px'
                                        }}
                                    >
                                        <VisuallyHiddenInput
                                            hidden
                                            multiple
                                            ref={useRefFileInput}
                                            onChange={(event) => handleFileAttachment(event)}
                                            name='attachment_file'
                                            accept="
                                                image/jpeg,image/jpg,image/png
                                                ,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document
                                                ,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
                                                ,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation
                                            "
                                            type="file"
                                            value={''}
                                        />
                                        ファイルを添付する
                                    </Typography>
                                </Stack>
                                {
                                    (() => {
                                        let attachmentFiles = filesData;
                                        if (attachmentFiles && attachmentFiles.length > 0) {
                                            return (
                                                <Stack direction={'row'} spacing={1} useFlexGap sx={{flexWrap: 'wrap'}}>
                                                    {
                                                        attachmentFiles.map((file: any, index: number) => {
                                                            return (
                                                                <Chip
                                                                    key={index}
                                                                    variant="outlined"
                                                                    label={file.name}
                                                                    onDelete={handleDeleteFile(file, index)}
                                                                    sx={{ fontSize: '15px' }}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </Stack>
                                            )
                                        }
                                    })()
                                }
                            </Stack>
                            <FormHelperText
                                error={errorFile.length > 0}
                            >
                                {errorFile}
                            </FormHelperText>
                        </Grid>

                        <Box sx={styles.footer}>
                            <ButtonCustom child={'戻る'} onClick={handleBackToStep2} />
                            <Box sx={{ flex: '1 1 auto' }} />
                            <ButtonCustom child={'送信する'} isSubmit onClick={handleSubmit} disabled={isLoading} />
                        </Box>
                        <ModalConfirm
                            type={typeModal}
                            openModalConfirm={openModalConfirm}
                            isLoading={isLoading}
                            closeModalConfirm={() => setOpenModalConfirm(false)}
                            onConfirm={() => {
                                submit()
                            }}
                            title={titleModalConfirm}
                            message={messageModalConfirm}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}

export default AdjustmentStep3

const styles = {
    formBackground: {
        width: '100%',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
    },
    label: {
        lineHeight: 1.4,
        fontSize: '15px',
        fontWeight: '500',
        height: '21px',
        margin: 'auto 0',
    },
    adjustmentTitle: {
        p: { xs: '20px', sm: '30px', md: 5 },
        display: 'table',
        tableLayout: 'fixed',
        width: '100%',
        height: '100%',
        background: 'rgba(255, 255, 255, 0.9)',
        boxShadow: '0px 4px 120px rgba(0, 0, 0, 0.25)',
        borderRadius: '20px',
    },
    adjustmentInfo: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '7px',
        paddingLeft: {
            xs: '0px',
            sm: '20px',
        },
    },
    boxScheduleEventMenu: {
        color: '#FFF',
        borderRadius: '10px',
        p: '0px 10px',
        display: 'flex',
        alignItems: 'center',
    },
    boxScheduleEventMenuText: {
        fontSize: '12px',
        fontWeight: '500',
    },
    invitees: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '48px',
    },
    boxAddInvitee: { display: 'flex', ml: '7px', cursor: 'pointer', gap: '8px' },
    boxAddInviteeIcon: {
        fontSize: '21px',
    },
    invitee: {
        display: 'flex',
        direction: 'row',
        mb: '10px',
    },
    labelAddInvitee: {
        textDecorationLine: 'underline',
        mt: '1px',
        fontSize: '12px',
    },
    iconRemove: {
        color: '#DC571E',
        fontSize: '30px',
        cursor: 'pointer',
        mt: '8px',
    },
    footer: { display: 'flex', flexDirection: 'row' },
    boxSearchName: {
        mt: '3px',
        width: '100%',
        position: 'absolute',
        background: '#ffffff',
        boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
        outline: '1px solid rgba(226, 232, 240, 0.3)',
        borderRadius: '8px',
        zIndex: 100,
        cursor: 'pointer',
        '& :first-of-type': {
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
        },
        '& :last-child': {
            borderBottomLeftRadius: '6px',
            borderBottomRightRadius: '6px',
        },
        overflow: 'auto',
        maxHeight: '300px',
        color: '#000000',
    },
    boxSearchEmail: {
        mt: '3px',
        width: {
            xs: 'calc(100% - 8px)',
            sm: 'calc(100% - 16px)',
        },
        position: 'absolute',
        background: '#ffffff',
        boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
        outline: '1px solid rgba(226, 232, 240, 0.3)',
        borderRadius: '8px',
        zIndex: 100,
        cursor: 'pointer',
        '& :first-of-type': {
            borderTopLeftRadius: '6px',
        },
        '& :last-child': {
            borderBottomLeftRadius: '6px',
        },
        overflow: 'auto',
        maxHeight: '300px',
        color: '#000000',
    },
    formCheckBox: {
        margin: '20px 20px 0 -4px',
        '.MuiTypography-root': {
            fontSize: '15px',
            fontWeight: '500',
        },
    },
    checkBox: {
        padding: '0 7px 0 1px',
    },
}
