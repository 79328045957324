import {
    Box,
    Grid,
    Modal,
    Pagination,
    Typography,
    TableBody,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    FormHelperText,
    keyframes,
    FormControlLabel,
    Checkbox,
    Stack,
    Chip,
    IconButton
} from '@mui/material'
import { styled } from '@mui/material/styles';
import GridModal from '@mui/material/Unstable_Grid2'
import { useEffect, useRef, useState, useLayoutEffect } from 'react'
import { formatDate, formatTargetDatePeriod } from '../../../helpers/formatDate'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import {
    selectFilterSchedules,
    selectMetaSchedules,
    selectScheduleNewUpdate,
    selectSchedules,
    topActions,
    selectLoadingSchedules,
    selectScheduleNewCancel,
    selectScheduleFinalized,
} from '../topSlice'
import { limitCharacter } from '../../../helpers/string'
import scheduleApi from 'src/api/scheduleApi'
import { Order, Schedule, Status } from 'src/models'
import { Link, useNavigate } from 'react-router-dom'
import { useSnackBar } from 'src/components/Common/SnackBarContext'
import ModalConfirm from 'src/components/Common/ModalConfirm'
import { TextInput } from 'src/components/TextInput'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import SvgIconEdit from 'src/components/Icons/SvgIconEdit'
import { tableHeaderText, tableRowAlign, tableBodyText, labelOfInput, labelOfInputAlign } from 'src/theme'
import ButtonCustom from 'src/components/Common/ButtonCustom'
import { CheckCircleIcon } from '../../../components/Icons'
import ChevronDownIcon from 'src/components/Icons/ChevronDownIcon'
// import { useDeviceType } from '../../../helpers/device'
import { cutString, handleSortByStartTime, replaceGaroonName } from '../../../helpers/helpers'
import LinearProgressWithLabel from 'src/components/Common/LinearProgressWithLabel'
import moment from 'moment'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { selectCurrentUser } from 'src/pages/Auth/authSlice'
import { forEach } from 'lodash';
import CopyIcon from 'src/components/Icons/CopyIcon';
import adjustmentApi from 'src/api/adjustmentApi';
import dayjs from "dayjs"
import { CONSTANTS } from "src/config/constants"

interface TopDataTableProps {
    tabLabel: string
}

function TopDataTable(props: TopDataTableProps) {
    const dispatch = useAppDispatch()
    // const device = useDeviceType()
    const filter = useAppSelector(selectFilterSchedules)
    const schedules = useAppSelector(selectSchedules)
    const metaSchedules = useAppSelector(selectMetaSchedules)
    const scheduleNewUpdate = useAppSelector(selectScheduleNewUpdate)
    const scheduleFinalized = useAppSelector(selectScheduleFinalized)
    const loadingSchedules = useAppSelector(selectLoadingSchedules)

    const scheduleNewCancel = useAppSelector(selectScheduleNewCancel)

    const currentUser = useAppSelector(selectCurrentUser)

    const navigate = useNavigate()

    const [isShowModal, setIsShowModal] = useState<boolean>(false)
    const [cancellingSchedule, setCancellingSchedule] = useState<any>({})
    const [mailSubject, setMailSubject] = useState<string>('【GLOBAL PRODUCE】予定「{subject}」キャンセルのお知らせ')
    const [mailBody, setMailBody] = useState<string>('{name} 様<br><br>いつもお世話になっております。<br>グローバルプロデュースの${name}です。<br><br>下記の予定がキャンセルになりましたので、ご案内申し上げます。<br>タイトル：{subject}<br>日時：{date_time}<br><br>ご確認のほどよろしくお願い致します。')
    const [order, setOrder] = useState<any>({
        id: 'asc',
        subject: 'asc',
        start_time: 'asc',
        create_at: 'asc',
    })
    const [isCCInternalParticipants, setIsCCInternalParticipants] = useState<boolean>(true)

    // open modal confirm copy
    const { showSnackBar } = useSnackBar()
    const [openModalConfirm, setOpenModalConfirm] = useState(false)
    const [action, setAction] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [titleModalConfirm, setTitleModalConfirm] = useState('')
    const [messageModalConfirm, setMessageModalConfirm] = useState('')
    const [scheduleCopy, setScheduleCopy] = useState<any>(null)
    const [scheduleDelete, setScheduleDelete] = useState<any>(null)

    const cancelingRef = useRef<boolean>(false)
    const copyingRef = useRef<boolean>(false)
    const edittingRef = useRef<boolean>(false)

    const [validateSubject, setValidateSubject] = useState<any>({
        isValidMaxLength: true,
        isValidRequired: true,
    })
    const [validateBody, setValidateBody] = useState<any>({
        isValidMaxLength: true,
        isValidRequired: true,
    })

    const [width, setWidth] = useState<number>(window.innerWidth)
    const [isShowModalSendReminder, setIsShowModalSendReminder] = useState<boolean>(false)
    const remindRef = useRef<boolean>(false)
    const [remindEmailSubject, setRemindEmailSubject] = useState<string>(``)
    const [remindEmailBody, setRemindEmailBody] = useState<string>(``)
    const [remindSchedule, setRemindSchedule] = useState<any>({})
    const [validateRemindSubject, setValidateRemindSubject] = useState<any>({
        isValidMaxLength: true,
        isValidRequired: true,
    })
    const [validateRemindBody, setValidateRemindBody] = useState<any>({
        isValidMaxLength: true,
        isValidRequired: true,
    })

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const handleConfirmCopy = (schedule: any) => {
        setScheduleCopy(schedule)
        copyingRef.current = true
        setAction('CANCEL')
        setTitleModalConfirm('コピーの確認')
        setMessageModalConfirm('この予定をコピーしますがよろしいですか？')
        setOpenModalConfirm(true)
    }

    const handleConfirmDelete = (schedule: any) => {
        setScheduleDelete(schedule)
        setAction('DELETE')
        setTitleModalConfirm('削除の確認')
        setMessageModalConfirm('この予定を削除しますがよろしいですか？')
        setOpenModalConfirm(true)
    }

    const closeModalConfirm = () => {
        setScheduleCopy(null)
        setScheduleDelete(null)
        setOpenModalConfirm(false)
        setTitleModalConfirm('')
        setMessageModalConfirm('')
        copyingRef.current = false
        cancelingRef.current = false
        remindRef.current = false
    }

    const handleRemindUser = (schedule: any) => {
        setRemindSchedule(schedule);
        remindRef.current = true
        setRemindEmailSubject(`【GLOBAL PRODUCE】${schedule?.subject || ''}／スケジュールご確認のリマインド`);
        setRemindEmailBody(
            `{name} 様<br><br>`
                + `いつもお世話になっております。<br>`
                + `グローバルプロデュースの${replaceGaroonName(schedule?.user, width, true)}です。<br><br>`
                + `「${schedule?.subject || ''}」について、<br>`
                + `日程調整の回答をご連絡頂いていない方へのリマインドとなります。<br><br>`
                + `お手数ですが、下記リンクより参加可能な日時をお知らせください。<br>`
                + `{url}<br><br>`
                + `回答期限を過ぎているため大変恐縮ですが、<br>`
                + `<strong>本日中</strong>にはご返信を頂けますと大変幸いです。<br><br>`
                + `----------------------------------------------------<br>`
                + `株式会社グローバルプロデュース<br>`
                + `${replaceGaroonName(schedule?.user, width, true)}<br>`
                + `〒150-0001    東京都渋谷区神宮前5-52-2<br>`
                + `青山オーバルビル13F</br>`
                + `OFFICE／03-5738-2117 （FAX2118）<br>`
                + `https://www.global-produce.jp/`
        );
        
        setIsShowModalSendReminder(true);
    }

    const sendRemindMail = () => {
        setIsLoading(true);
        if (!remindSchedule.id) {
            showSnackBar(true, '入力エラーがあります。修正して再度実行ボタンをクリックしてください。', 'error')
            setIsShowModalSendReminder(false)
            setIsLoading(false);
            return
        }

        const isMailBodyValid = remindEmailBody.length === 0 || remindEmailBody.length > 5000
        const isMailSubjectValid = remindEmailSubject.length === 0 || remindEmailSubject.length > 100

        if (isMailBodyValid) {
            setValidateRemindBody({
                ...validateRemindBody,
                isValidRequired: remindEmailBody.length === 0 ? false : true,
                isValidMaxLength: remindEmailBody.length > 5000 ? false : true,
            })
        }

        if (isMailSubjectValid) {
            setValidateRemindSubject({
                ...validateRemindSubject,
                isValidRequired: remindEmailSubject.length === 0 ? false : true,
                isValidMaxLength: remindEmailSubject.length > 100 ? false : true,
            })
        }

        if (isMailBodyValid || isMailSubjectValid) {
            showSnackBar(true, '入力エラーがあります。修正して再度実行ボタンをクリックしてください。', 'error')
            setIsLoading(false);
            return
        }

        setIsShowModalSendReminder(false)
        setIsLoading(false);
        remindRef.current = false

        const params = {
            email_subject: remindEmailSubject,
            email_body: remindEmailBody,
            attachment_file: filesData,
        }

        const sid = remindSchedule.id;
        scheduleApi
            .remindEmail(sid, params)
            .then((res: any) => {
                if (res?.success) {
                    showSnackBar(true, `リマインダーメールの送信成功`, 'success')
                } else {
                    console.log(res.message)
                }
                setFileAttachment([])
                setFilesData([])
                setErrorFile('')

                // reset file input
                if(useRefFileInput && useRefFileInput.current) {
                    useRefFileInput.current.value = '';
                }
            })
            .catch((err) => {
                console.log(err)
                setFileAttachment([])
                setFilesData([])
                setErrorFile('')

                // reset file input
                if(useRefFileInput && useRefFileInput.current) {
                    useRefFileInput.current.value = '';
                }
            })
    }

    const handleScheduleCopy = () => {
        if (scheduleCopy.id) {
            let data = {
                id: scheduleCopy.id,
            }
            setIsLoading(true)
            scheduleApi
                .copy(data)
                .then((res: any) => {
                    if (res?.success) {
                        showSnackBar(true, `予定「${scheduleCopy.subject}」正常にコピーされました`, 'success')
                        // redirect to edit page
                        navigate(`/adjustment/step1/${res.data.schedule.id}`)
                    } else {
                        showSnackBar(true, `予定「${scheduleCopy.subject}」コピーに失敗しました`, 'error')
                    }
                    closeModalConfirm()
                    setIsLoading(false)
                })
                .catch(() => {
                    showSnackBar(true, `予定「${scheduleCopy.subject}」コピーに失敗しました`, 'error')
                    closeModalConfirm()
                    setIsLoading(false)
                })
        }
    }

    // useEffect(() => {
    //     dispatch(topActions.setSchedulesList([]))
    // }, [])

    // useEffect(() => {
    //     dispatch(topActions.fetchSchedules(filter))
    // }, [filter])

    const handleScheduleDelete = () => {
        if (scheduleDelete.id) {
            let data = {
                id: scheduleDelete.id,
            }
            setIsLoading(true)
            scheduleApi
                .delete(data)
                .then((res: any) => {
                    if (res?.success) {
                        dispatch(
                            topActions.setFilter({
                                ...filter,
                                flag: !filter.flag,
                            }),
                        )
                        showSnackBar(true, `予定「${scheduleDelete.subject}」正常に削除されました`, 'success')
                    } else {
                        showSnackBar(true, `予定「${scheduleDelete.subject}」削除に失敗しました`, 'error')
                    }
                    closeModalConfirm()
                    setIsLoading(false)

                })
                .catch(() => {
                    showSnackBar(true, `予定「${scheduleDelete.subject}」削除に失敗しました`, 'error')
                    closeModalConfirm()
                    setIsLoading(false)
                })
        }
    }

    const handleCancel = (schedule: any) => {
        setCancellingSchedule(schedule)
        cancelingRef.current = true
        setMailSubject(`【GLOBAL PRODUCE】${schedule.subject}／キャンセルのお知らせ`)
        setIsCCInternalParticipants(true)
        if (schedule.start_time) {
            setMailBody(
                `{name} 様<br><br>`
                + `いつもお世話になっております。<br>`
                + `グローバルプロデュースの${replaceGaroonName(schedule?.user, width, true)}です。<br><br>`
                + `下記の予定がキャンセルになりました。<br>`
                + `予定タイトル：${schedule.subject}<br>`
                + `予定日時：${schedule?.start_time ? moment(schedule?.start_time).format('YYYY/M/D(ddd) HH:mm') : ''} 〜 (${schedule?.end_time && schedule?.start_time ? moment(schedule?.end_time).diff(moment(schedule?.start_time), 'minutes') : ''}分間)<br><br>`
                + `引き続きよろしくお願い致します。<br><br>`
                + `----------------------------------------------------<br>`
                + `株式会社グローバルプロデュース<br>`
                + `${replaceGaroonName(schedule?.user, width, true)}<br>`
                + `〒150-0001    東京都渋谷区神宮前5-52-2<br>`
                + `青山オーバルビル13F</br>`
                + `OFFICE／03-5738-2117 （FAX2118）<br>`
                + `https://www.global-produce.jp/`)
        } else {
            setMailBody(
                `{name} 様<br><br>`
                + `いつもお世話になっております。<br>`
                + `グローバルプロデュースの${replaceGaroonName(schedule?.user, width, true)}です。<br><br>`
                + `下記の予定調整依頼がキャンセルになりました。<br>`
                + `予定タイトル：${schedule.subject}<br><br>`
                + `引き続きよろしくお願い致します。<br><br>`
                + `----------------------------------------------------<br>`
                + `株式会社グローバルプロデュース<br>`
                + `${replaceGaroonName(schedule?.user, width, true)}<br>`
                + `〒150-0001    東京都渋谷区神宮前5-52-2<br>`
                + `青山オーバルビル13F</br>`
                + `OFFICE／03-5738-2117 （FAX2118）<br>`
                + `https://www.global-produce.jp/`)
        }

        setIsShowModal(true)
    }

    const handleGoToFinalize = (schedule: any) => {
        navigate(`/finalize/${schedule.id}/step1`)
    }

    const sendEmail = () => {
        setIsLoading(true)
        const isMailBodyValid = mailBody.length === 0 || mailBody.length > 5000
        const isMailSubjectValid = mailSubject.length === 0 || mailSubject.length > 100

        if (isMailBodyValid) {
            setValidateBody({
                ...validateBody,
                isValidRequired: mailBody.length === 0 ? false : true,
                isValidMaxLength: mailBody.length > 5000 ? false : true,
            })
        }

        if (isMailSubjectValid) {
            setValidateSubject({
                ...validateSubject,
                isValidRequired: mailSubject.length === 0 ? false : true,
                isValidMaxLength: mailSubject.length > 100 ? false : true,
            })
        }

        if (isMailBodyValid || isMailSubjectValid) {
            showSnackBar(true, '入力エラーがあります。修正して再度実行ボタンをクリックしてください。', 'error')
            setIsLoading(false)
            return
        }

        setIsShowModal(false)
        setIsLoading(false)
        cancelingRef.current = false
        dispatch(
            topActions.cancelSchedule({ 
                id: cancellingSchedule.id, 
                email_subject: mailSubject, 
                email_body: mailBody,
                is_cc_internal_participants: isCCInternalParticipants,
            }),
        )
    }

    const closeModal = () => {
        setCancellingSchedule({})
        setIsShowModal(false)
        cancelingRef.current = false
        setMailBody('{氏名} 様\n\nSchedule has been cancelled.')
    }

    const closeRemindModal = () => {
        setRemindSchedule({});
        setIsShowModalSendReminder(false)
        remindRef.current = false
        setRemindEmailBody('')
        setFileAttachment([])
        setFilesData([])
        setErrorFile('')

        // reset file input
        if(useRefFileInput && useRefFileInput.current) {
            useRefFileInput.current.value = '';
        }
    }

    const updateMailSubject = (text: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (text.currentTarget.value.length > 100) {
            setValidateSubject({
                ...validateSubject,
                isValidMaxLength: false,
                isValidRequired: true,
            })
        } else if (text.currentTarget.value.length === 0) {
            setValidateSubject({
                ...validateSubject,
                isValidMaxLength: true,
                isValidRequired: false,
            })
        } else {
            setValidateSubject({
                ...validateSubject,
                isValidMaxLength: true,
                isValidRequired: true,
            })
        }
        setMailSubject(text.currentTarget.value)
    }

    const updateMailBody = (text: any) => {
        if (text.length > 5000) {
            setValidateBody({
                ...validateBody,
                isValidMaxLength: false,
                isValidRequired: true,
            })
        } else if (text.length === 0) {
            setValidateBody({
                ...validateBody,
                isValidMaxLength: true,
                isValidRequired: false,
            })
        } else {
            setValidateBody({
                ...validateBody,
                isValidMaxLength: true,
                isValidRequired: true,
            })
        }
        setMailBody(text)
    }

    const updateRemindMailSubject = (text: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (text.currentTarget.value.length > 100) {
            setValidateRemindSubject({
                ...validateRemindSubject,
                isValidMaxLength: false,
                isValidRequired: true,
            })
        } else if (text.currentTarget.value.length === 0) {
            setValidateRemindSubject({
                ...validateRemindSubject,
                isValidMaxLength: true,
                isValidRequired: false,
            })
        } else {
            setValidateRemindSubject({
                ...validateRemindSubject,
                isValidMaxLength: true,
                isValidRequired: true,
            })
        }
        setRemindEmailSubject(text.currentTarget.value)
    }

    const updateRemindMailBody = (text: any) => {
        if (text.length > 5000) {
            setValidateRemindBody({
                ...validateRemindBody,
                isValidMaxLength: false,
                isValidRequired: true,
            })
        } else if (text.length === 0) {
            setValidateRemindBody({
                ...validateRemindBody,
                isValidMaxLength: true,
                isValidRequired: false,
            })
        } else {
            setValidateRemindBody({
                ...validateRemindBody,
                isValidMaxLength: true,
                isValidRequired: true,
            })
        }
        setRemindEmailBody(text)
    }

    const formatTargetDateTimePeriod = (schedule: any) => {
        const startTimeString = schedule.start_time
        const endTimeString = schedule.end_time
        if (!startTimeString || !endTimeString) {
            if (!schedule.adjustment_start_date || !schedule.adjustment_end_date) {
                return []
            }

            const formatDate = formatTargetDatePeriod(schedule.adjustment_start_date, schedule.adjustment_end_date)
            const convertDate = formatDate.split(' ')
            if (convertDate[0].slice(0, -1) == convertDate[1]) {
                return [convertDate[0].slice(0, -1)];
            }
            return [convertDate[0].slice(0, -1), '〜' + convertDate[1]]
        }

        const startTime = new Date(startTimeString)
        const endTime = new Date(endTimeString)
        const startDateString = `${startTime.getFullYear()}/${(startTime.getMonth() + 1)
            .toString()
            }/${startTime.getDate().toString()}`

        const endDateString = `${endTime.getFullYear()}/${(endTime.getMonth() + 1)
            .toString()
            }/${endTime.getDate().toString()}`

        let formattedTimeRange = []

        if (startDateString === endDateString) {
            formattedTimeRange = [
                `${startDateString}`,
                `${startTime.getHours().toString()}:${startTime
                    .getMinutes()
                    .toString()
                    .padStart(2, '0')} 〜 ${endTime.getHours().toString()}:${endTime
                    .getMinutes()
                    .toString()
                    .padStart(2, '0')}`,
            ]
        } else {
            formattedTimeRange = [`${startDateString}`, `〜${endDateString}`]
        }
        return formattedTimeRange
    }

    const handleSortBy = (key: string) => {
        setOrder({
            ...order,
            [key]: order[key] === 'desc' ? 'asc' : 'desc',
        })

        if (key === 'start_time') {
            key = handleSortByStartTime(filter['status'])
        }

        dispatch(
            topActions.setFilter({
                ...filter,
                orderBy: key,
                order: filter.order === Order.DESC ? Order.ASC : Order.DESC,
            }),
        )
    }

    const getMessageNoRecord = (tabLabel: string) => {
        let message = tabLabel + 'の予定はありません。';
        if (tabLabel == '下書き') {
            message = '下書き保存された予定はありません。';
        }
        if (tabLabel == '過去の履歴') {
            message = '過去の履歴はありません。';
        }
        if (tabLabel == 'キャンセル') {
            message = 'キャンセルされた予定はありません。';
        }
        return message;
    }

    const [progress, setProgress] = useState(0);
    useEffect(() => {
        // set progress loading
        if (loadingSchedules) {
            setProgress(1);
            dispatch(topActions.setSchedulesList([]))
            return;
        } 
        let i = 1;
        let total = schedules.length > 0 ? schedules.length : 1
        const timer = setInterval(() => {
            if (i > total) {
                clearInterval(timer);
                setProgress(0);
                return;
            }
            setProgress((i / total) * 100)
            i++;
        }, 100);

        return () => clearInterval(timer);
    }, [loadingSchedules]);

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const fileMimeType = [
        'image/jpeg',
        'image/png',
        'image/jpg',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    ]

    const [fileAttachment, setFileAttachment] = useState<any[]>([]);
    const [filesData, setFilesData] = useState<any[]>([]);
    const useRefFileInput = useRef<any>(null);
    const [errorFile, setErrorFile] = useState('');
    const handleFileAttachment = (event: any) => {
        const {files} = event.target;

        const validImageFiles = [];
        let isValidate = false;
        setErrorFile('');
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (!fileMimeType.includes(file.type)) {
                // reset file input
                if(useRefFileInput && useRefFileInput.current) {
                    useRefFileInput.current.value = '';
                }
                setErrorFile('ファイル形式が正しくありません');
                isValidate = true;
                return;
            }
            if (file.size > 10485760) {
                // reset file input
                if(useRefFileInput && useRefFileInput.current) {
                    useRefFileInput.current.value = '';
                }
                setErrorFile('10MB以下のファイルを添付してください。');
                isValidate = true;
                return;
            }

            validImageFiles.push(file);
        }

        if (!isValidate && validImageFiles.length > 0) {
            setFileAttachment(validImageFiles);
        }
    }

    useEffect(() => {
        // reset file input
        if(useRefFileInput && useRefFileInput.current) {
            useRefFileInput.current.value = '';
        }

        let fileReaders: any[] = [];
        let isCancel = false;
        if (fileAttachment.length > 0) {
            forEach(fileAttachment, (file: any) => {
                let fileReader = new FileReader();
                fileReaders.push(fileReader);
                fileReader.onload = (e: any) => {
                    const { result } = e.target;
                    if (result && !isCancel) {
                        setFilesData((prev: any) => {
                            return [
                                ...prev,
                                {
                                    name: file.name,
                                    size: file.size,
                                    type: file.type,
                                    data: result,
                                },
                            ];
                        });    
                    }
                };
                fileReader.readAsDataURL(file);
            })
        }

        return () => {
            isCancel = true;
            fileReaders.forEach(fileReader => {
                if (fileReader.readyState === 1) {
                    fileReader.abort()
                }
            })
        }
    }, [fileAttachment]);

    const handleDeleteFile = (file: any, index: number) => () => {
        setFilesData((prev: any) => {
            const newFilesData = [...prev];
            newFilesData.splice(index, 1);
            return newFilesData;
        });
    };

    const handleCopy = (hash: string, scheduleId: number) => {
        const url = `${process.env.REACT_APP_EXTERNAL_URL}${hash}`;    
        adjustmentApi
                            .getAdjustmentTimeByScheduleId(scheduleId)
                            .then((res: any) => {
                                if (res?.success) {
                                    const data = res.data;
                                    const textToCopy = `
候補日時:
${data.map((value: any) => {
    return `${dayjs(value.start_time).format(
        `YYYY/M/D(${
            CONSTANTS.DAY_OF_WEEK[dayjs(value.start_time).day()]
        }) ${dayjs(value.start_time).format('HH:mm')}～（${
            value.duration
                ? value.duration === 1439
                    ? '終日'
                    : value.duration + '分間'
                : ''
        }）`
    )}\n`;
}).join('')} 
日程調整URL:
${url}
                                    `;
                                    navigator.clipboard.writeText(textToCopy.trim());
                                }
                            })
                            .catch((err: any) => {
                                navigator.clipboard.writeText(url.trim());
                                console.log(err)
                            })

        showSnackBar(true, `候補日と調整URLをコピーしました。`, 'success')
    };

    const componentAttachmentFile = () => {
        return (
            <>
                <Stack 
                    direction={
                        {
                            xs: 'column',
                            sm: 'row',
                        }
                    } 
                    spacing={1}
                >
                    <Typography
                        component="label"
                        sx={{
                            color: '#1976d2',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            fontSize: '15px',
                            minWidth: '140px',
                            padding: '4px 0 4px 0px',
                        }}
                    >
                        <VisuallyHiddenInput
                            hidden
                            multiple
                            ref={useRefFileInput}
                            onChange={(event) => handleFileAttachment(event)}
                            name='attachment_file'
                            accept="
                                image/jpeg,image/jpg,image/png
                                ,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document
                                ,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
                                ,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation
                            "
                            type="file"
                            value={''}
                        />
                        ファイルを添付する
                    </Typography>
                    {
                        (() => {
                            let attachmentFiles = filesData;
                            if (attachmentFiles && attachmentFiles.length > 0) {
                                return (
                                    <Stack direction={'row'} spacing={1} useFlexGap sx={{flexWrap: 'wrap'}}>
                                        {
                                            attachmentFiles.map((file: any, index: number) => {
                                                return (
                                                    <Chip
                                                        key={index}
                                                        variant="outlined"
                                                        label={file.name}
                                                        onDelete={handleDeleteFile(file, index)}
                                                        sx={{ fontSize: '15px' }}
                                                    />
                                                )
                                            })
                                        }
                                    </Stack>
                                )
                            }
                        })()
                    }
                </Stack>
                <FormHelperText
                    error={errorFile.length > 0}
                >
                    {errorFile}
                </FormHelperText>
            </>
        )
    }

    return (
        <Box sx={styles.container}>
            <TableContainer sx={{ overflowX: progress <= 0 ? 'auto' : 'hidden' }}>
                <Table sx={styles.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                onClick={() => handleSortBy('id')}
                                sx={{ position: 'relative', cursor: 'pointer' }}
                            >
                                No
                                <Box sx={{ position: 'absolute', top: '9px', left: '38px' }}>
                                    <OrderComponent order={order.id} orderThis={filter.orderBy === 'id'} />
                                </Box>
                            </TableCell>
                            <TableCell
                                onClick={() => handleSortBy('subject')}
                                sx={{ position: 'relative', cursor: 'pointer' }}
                            >
                                予定タイトル
                                <Box sx={{ position: 'absolute', top: '9px', left: '108px' }}>
                                    <OrderComponent order={order.subject} orderThis={filter.orderBy === 'subject'} />
                                </Box>
                            </TableCell>
                            <TableCell
                                sx={{ width: '132px', position: 'relative', cursor: 'pointer' }}
                                onClick={() => handleSortBy('start_time')}
                            >
                                調整日時
                                <Box sx={{ position: 'absolute', top: '9px', left: '78px' }}>
                                    <OrderComponent
                                        order={order.start_time}
                                        orderThis={
                                            filter.orderBy === 'start_time' ||
                                            filter.orderBy === 'adjustment_start_date'
                                        }
                                    />
                                </Box>
                            </TableCell>
                            <TableCell>参加者</TableCell>
                            <TableCell>調整者</TableCell>
                            <TableCell>招待者</TableCell>
                            <TableCell
                                onClick={() => handleSortBy('created_at')}
                                sx={{ position: 'relative', cursor: 'pointer' }}
                            >
                                登録日時
                                <Box sx={{ position: 'absolute', top: '9px', left: '78px' }}>
                                    <OrderComponent
                                        order={order.created_at}
                                        orderThis={filter.orderBy === 'created_at'}
                                    />
                                </Box>
                            </TableCell>
                            <TableCell sx={{ width: '130px' }}></TableCell>
                        </TableRow>
                    </TableHead>
                    {
                        (() => {
                            if (progress <= 0) {
                                return (
                                    <TableBody>
                                        {
                                            (() => {
                                                if (schedules.length > 0) {
                                                    return (
                                                        schedules.length > 0 && schedules.map((schedule: any, index: number) => {
                                                            let internalUsers: any[] = []
                                                            let externalUsers: any[] = []
                                                            if (schedule.participant != undefined && schedule.participant != null) {
                                                                schedule.participant.map((user: any) => {
                                                                    if (user.user.is_employee) {
                                                                        internalUsers.push(user)
                                                                    } else {
                                                                        externalUsers.push(user)
                                                                    }
                                                                })
                                                            }
                                                            return (
                                                                <TableRow
                                                                    key={schedule.id}
                                                                    tabIndex={0}
                                                                    hover
                                                                    sx={[
                                                                        {
                                                                            cursor:
                                                                                !schedule.is_cancelled &&
                                                                                filter.status === 'ADJUSTING'
                                                                                    ? 'pointer'
                                                                                    : 'unset',
                                                                            transition: '0.25s linear',
                                                                        },
                                                                        ((index === 0 &&
                                                                            schedule.id === scheduleNewUpdate?.id) ||
                                                                            schedule.id == scheduleNewCancel?.id ||
                                                                            schedule.id == scheduleFinalized?.id ) && {
                                                                                animation: 'fadeout 4s',
                                                                            },
                                                                        width > 960 && {
                                                                            '& > :last-child': {
                                                                                opacity: 0,
                                                                            },
                                                                            ':hover > :last-child': {
                                                                                animation: `fadeInOpacity 0.25s linear`,
                                                                                opacity: 1,
                                                                            },
                                                                            ':not(:hover) > :last-child': {
                                                                                opacity: 0,
                                                                            },
                                                                        },
                                                                    ]}
                                                                    onClick={() => {
                                                                        if (
                                                                            !schedule.is_cancelled &&
                                                                            filter.status === 'ADJUSTING' &&
                                                                            !copyingRef.current &&
                                                                            !cancelingRef.current &&
                                                                            !edittingRef.current &&
                                                                            !remindRef.current
                                                                        ) {
                                                                            handleGoToFinalize(schedule)
                                                                        }
                                                                    }}
                                                                >
                                                                    <TableCell>{schedule.id}</TableCell>
                                                                    <TableCell sx={styles.subjectCell}>
                                                                        <Box sx={styles.overlayItem}>
                                                                            <Typography
                                                                                sx={{
                                                                                    textDecoration:
                                                                                        !schedule.is_cancelled &&
                                                                                        filter.status === 'ADJUSTING'
                                                                                            ? 'underline'
                                                                                            : 'none',
                                                                                }}
                                                                            >
                                                                                {schedule.subject}
                                                                            </Typography>
                                                                        </Box>
                                                                            {schedule.hash && props.tabLabel === '調整中' && (
                                                                                <Box sx={[styles.overlayItem, styles.externalUrl]}
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        handleCopy(schedule.hash, schedule.id);
                                                                                    }}
                                                                                >
                                                                                    <Typography sx={{ fontSize: '14px' }}>{'候補日と調整URLをコピー'}</Typography>
                                                                                    <IconButton size="small">
                                                                                        <ContentCopyRoundedIcon sx={styles.externalUrlIcon} />
                                                                                    </IconButton>
                                                                                </Box>
                                                                            )}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {formatTargetDateTimePeriod(schedule).map((item) => (
                                                                            <Grid sx={{ whiteSpace: 'nowrap' }} key={item}>
                                                                                {item}
                                                                            </Grid>
                                                                        ))}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Grid container direction="column">
                                                                            {internalUsers.length > 0 &&
                                                                                internalUsers.map((internalUser: any, index: number) => (
                                                                                    <Typography
                                                                                        key={internalUser.user.id}
                                                                                        sx={{ whiteSpace: 'nowrap' }}
                                                                                    >
                                                                                        {limitCharacter(replaceGaroonName(internalUser.user, width))}
                                                                                    </Typography>
                                                                                ))}
                                                                        </Grid>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Typography sx={{ whiteSpace: 'nowrap' }}>
                                                                            {limitCharacter(replaceGaroonName(schedule?.user, width))}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Grid>
                                                                            {externalUsers.length > 0 ? (
                                                                                externalUsers.map((externalUser: any, index: number) => (
                                                                                    <Typography
                                                                                        sx={{
                                                                                            display: 'flex',
                                                                                            direction: 'row',
                                                                                            position: 'relative',
                                                                                            cursor: 'pointer',
                                                                                        }}
                                                                                        key={`${externalUser.user_id}${index}`}
                                                                                        noWrap
                                                                                        component={'div'}
                                                                                    >
                                                                                        <Grid item>{cutString(externalUser.user.name)} </Grid>
                                                                                        {externalUser.is_confirmed && (
                                                                                            <Box sx={{ ml: '10px', mt: '3px' }}>
                                                                                                <CheckCircleIcon />
                                                                                            </Box>
                                                                                        )}
                                                                                    </Typography>
                                                                                ))
                                                                            ) : (
                                                                                <Typography>ー</Typography>
                                                                            )}
                                                                            {
                                                                                (() => {
                                                                                    if (externalUsers.length > 0 && filter.status === 'ADJUSTING') {
                                                                                        const checkInviteeResponse = externalUsers.some((user: any) => user.is_confirmed === false);
                                                                                        if (checkInviteeResponse) {
                                                                                            return (
                                                                                                <Typography
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        position: 'relative',
                                                                                                        cursor: 'pointer',
                                                                                                        lineHeight: 1.4, 
                                                                                                        fontSize: '14px',
                                                                                                        whiteSpace: 'normal',
                                                                                                        textDecoration: 'underline',
                                                                                                        minWidth: '170px',
                                                                                                        color: '#1976d2',
                                                                                                        paddingTop: '5px',
                                                                                                    }}
                                                                                                    onClick={() => handleRemindUser(schedule)}
                                                                                                    noWrap
                                                                                                    component={'div'}
                                                                                                >
                                                                                                    リマインドメールを送信
                                                                                                </Typography>
                                                                                            )
                                                                                        }
                                                                                    }
                                                                                })()
                                                                            }
                                                                        </Grid>
                                                                    </TableCell>
                                                                    <TableCell>{formatDate(new Date(schedule.created_at))}</TableCell>
                                                                    <TableCell>
                                                                        <Grid sx={styles.overlay}>
                                                                            <Box
                                                                                sx={styles.overlayItem}
                                                                                onMouseDown={() => handleConfirmCopy(schedule)}
                                                                            >
                                                                                <CopyComponent />
                                                                            </Box>
                                                                            {filter.status === 'ADJUSTING' && currentUser.is_employee && (
                                                                                <Box 
                                                                                    sx={styles.overlayItem}
                                                                                    onClick={() => {edittingRef.current = true}}
                                                                                >
                                                                                    <EditComponent scheduleId={schedule.id} />
                                                                                </Box>
                                                                            )}
                                                                            {['FINALIZED', 'ADJUSTING'].includes(filter.status) ? (
                                                                                !schedule.is_cancelled && (
                                                                                    <>
                                                                                        <Box
                                                                                            sx={styles.overlayItem}
                                                                                            onMouseDown={() => handleCancel(schedule)}
                                                                                        >
                                                                                            <CancelComponent />
                                                                                        </Box>
                                                                                    </>
                                                                                )
                                                                            ) : (
                                                                                <>
                                                                                    {filter.status === 'DRAFT' && (
                                                                                        <Box sx={styles.overlayItem}>
                                                                                            <EditComponent scheduleId={schedule.id} />
                                                                                        </Box>
                                                                                    )}
                
                                                                                    <Box
                                                                                        sx={styles.overlayItem}
                                                                                        onClick={() => handleConfirmDelete(schedule)}
                                                                                    >
                                                                                        <DeleteComponent />
                                                                                    </Box>
                                                                                </>
                                                                            )}
                                                                        </Grid>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                    )
                                                } else {
                                                    return (
                                                        <TableRow>
                                                            <TableCell sx={{borderBottom: 'none !important'}} colSpan={8}>
                                                                <Typography>{getMessageNoRecord(props.tabLabel)}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                            })()
                                        }
                                    </TableBody>
                                )
                            }
                        })()
                    }
                </Table>
            </TableContainer>
            {
                (() => {
                    let countPage = Math.floor((metaSchedules - 1) / filter.limit) + 1
                    if (progress > 0) {
                        return (
                            <Box sx={{ width: '200px', margin: '0 auto' }}>
                                <LinearProgressWithLabel value={progress} />
                            </Box>
                        )
                    } else if ( schedules.length > 0 && countPage > 1) {
                        return (
                            <>
                                <Pagination
                                    count={countPage}
                                    page={filter.page}
                                    showFirstButton={false}
                                    showLastButton={false}
                                    hidePrevButton={filter.page === 1}
                                    hideNextButton={filter.page === countPage}
                                    onChange={(event, value) => {
                                        dispatch(
                                            topActions.setFilter({
                                                ...filter,
                                                page: value,
                                            }),
                                        )
                                    }}
                                    sx={styles.pagination}
                                    siblingCount={0}
                                    boundaryCount={1}
                                />
                            </>
                        )
                    }
                })()
            }

            {/* cancel schedule modal */}
            <Modal open={isShowModal} onClose={() => closeModal()} sx={{ overflow: 'auto', display: "flex" }}>
                <Box sx={styles.modalBoxWrapper}>
                    <GridModal container xs={12} sx={{ display: 'flex', gap: 1 }}>
                        <GridModal xs={12} sx={{mb: 1}}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="is_cc_internal_participants"
                                        sx={{
                                            color: '#000000',
                                        }}
                                        checked={isCCInternalParticipants}
                                        onChange={(e) => setIsCCInternalParticipants(e.target.checked)}
                                    />
                                }
                                sx={{
                                    '.MuiTypography-root': {
                                        fontSize: '15px',
                                        fontWeight: '500',
                                    },
                                }}
                                label="参加者にもCCで送付する"
                            />
                        </GridModal>
                        <GridModal xs={12}>
                            <Typography sx={{ ...labelOfInput, ...labelOfInputAlign }}>件名</Typography>
                        </GridModal>
                        <GridModal xs={12}>
                            <TextInput
                                onChange={(text: any) => updateMailSubject(text)}
                                defaultValue={mailSubject}
                                error={!validateSubject.isValidMaxLength || !validateSubject.isValidRequired}
                            />

                            <FormHelperText
                                error={!validateSubject.isValidMaxLength || !validateSubject.isValidRequired}
                            >
                                {!validateSubject.isValidMaxLength &&
                                    '「件名」の長さは100文字以内である必要があります'}
                                {!validateSubject.isValidRequired && '「件名」は必須です'}
                            </FormHelperText>
                        </GridModal>
                        <GridModal xs={12}>
                            <Typography sx={{ ...labelOfInput, ...labelOfInputAlign, ...{ mt: 2 } }}>
                                本文
                            </Typography>
                        </GridModal>
                        <GridModal xs={12}>
                            <Typography 
                                component={'div'}
                                sx={{
                                    border: '1px solid',
                                    borderColor: !validateBody.isValidMaxLength || !validateBody.isValidRequired ? 'red' : '#666',
                                    borderRadius: '15px',
                                    fontSize: '15px',
                                }}
                            >
                                <CKEditor
                                    editor={DecoupledEditor}
                                    data={mailBody}
                                    config={{
                                        removePlugins: ['Title'],
                                        toolbar: [ 'undo', 'redo', '|', 'bold', 'fontColor' ],
                                        placeholder: '',
                                        translations: {
                                            '': {
                                                dictionary: {
                                                    'Remove color': '色を削除',
                                                    'Color picker': 'その他の色',
                                                    'Document colors': '選択された色',
                                                },
                                            }
                                        }
                                    }}
                                    onChange={(event: any, editor: any) => {
                                        const data = editor.getData()
                                        updateMailBody(data)
                                    }}
                                    onReady={(editor: any) => {
                                        editor?.ui?.getEditableElement()?.parentElement?.insertBefore(
                                            editor.ui.view.toolbar.element as Node,
                                            editor.ui.getEditableElement() as Node
                                        );

                                        editor.editing.view.change((writer: any) => {
                                            writer.setStyle({
                                                height: '260px',
                                            }, editor.editing.view.document.getRoot())
                                        })
                                    }}
                                />
                            </Typography>

                            <FormHelperText
                                error={!validateBody.isValidMaxLength || !validateBody.isValidRequired}
                            >
                                {!validateBody.isValidMaxLength &&
                                    '「本文」の長さは5000文字以内である必要があります'}
                                {!validateBody.isValidRequired && '「本文」は必須です'}
                            </FormHelperText>
                        </GridModal>
                    </GridModal>
                    <GridModal xs={12}>
                        <Box sx={[styles.footerModal, { justifyContent: 'center', mt: 5 }]}>
                            <ButtonCustom child={'戻る'} onClick={() => closeModal()} />
                            <Box sx={{ flex: '1 1 auto' }} />
                            <ButtonCustom isSubmit child={'送信する'} onClick={sendEmail} disabled={isLoading}/>
                        </Box>
                    </GridModal>
                </Box>
            </Modal>

            {/* remind schedule modal */}
            <Modal open={isShowModalSendReminder} onClose={() => closeRemindModal()} sx={{ overflow: 'auto', display: 'flex' }}>
                <Box sx={styles.modalBoxWrapper}>
                    <GridModal container xs={12} sx={{ display: 'flex', gap: 1 }}>
                        <GridModal xs={12}>
                            <Typography sx={{ ...labelOfInput, ...labelOfInputAlign }}>件名</Typography>
                        </GridModal>
                        <GridModal xs={12}>
                            <TextInput
                                onChange={(text: any) => updateRemindMailSubject(text)}
                                defaultValue={remindEmailSubject}
                                error={!validateRemindSubject.isValidMaxLength || !validateRemindSubject.isValidRequired}
                            />

                            <FormHelperText
                                error={!validateRemindSubject.isValidMaxLength || !validateRemindSubject.isValidRequired}
                            >
                                {!validateRemindSubject.isValidMaxLength &&
                                    '「件名」の長さは100文字以内である必要があります'}
                                {!validateRemindSubject.isValidRequired && '「件名」は必須です'}
                            </FormHelperText>
                        </GridModal>
                        <GridModal xs={12}>
                            <Typography sx={{ ...labelOfInput, ...labelOfInputAlign, ...{ mt: 2 } }}>
                                本文
                            </Typography>
                        </GridModal>
                        <GridModal xs={12}>
                            <Typography 
                                component={'div'}
                                sx={{
                                    border: '1px solid',
                                    borderColor: !validateRemindBody.isValidMaxLength || !validateRemindBody.isValidRequired ? 'red' : '#666',
                                    borderRadius: '15px',
                                    fontSize: '15px',
                                }}
                            >
                                <CKEditor
                                    editor={DecoupledEditor}
                                    data={remindEmailBody}
                                    config={{
                                        removePlugins: ['Title'],
                                        toolbar: [ 'undo', 'redo', '|', 'bold', 'fontColor' ],
                                        placeholder: '',
                                        translations: {
                                            '': {
                                                dictionary: {
                                                    'Remove color': '色を削除',
                                                    'Color picker': 'その他の色',
                                                    'Document colors': '選択された色',
                                                },
                                            }
                                        }
                                    }}
                                    onChange={(event: any, editor: any) => {
                                        const data = editor.getData()
                                        updateRemindMailBody(data)
                                    }}
                                    onReady={(editor: any) => {
                                        editor?.ui?.getEditableElement()?.parentElement?.insertBefore(
                                            editor.ui.view.toolbar.element as Node,
                                            editor.ui.getEditableElement() as Node
                                        );

                                        editor.editing.view.change((writer: any) => {
                                            writer.setStyle({
                                                height: '260px',
                                            }, editor.editing.view.document.getRoot())
                                        })
                                    }}
                                />
                            </Typography>

                            <FormHelperText
                                error={!validateRemindBody.isValidMaxLength || !validateRemindBody.isValidRequired}
                            >
                                {!validateRemindBody.isValidMaxLength &&
                                    '「本文」の長さは5000文字以内である必要があります'}
                                {!validateRemindBody.isValidRequired && '「本文」は必須です'}
                            </FormHelperText>
                        </GridModal>
                        <GridModal xs={12}>
                            {componentAttachmentFile()}
                        </GridModal>
                    </GridModal>
                    <GridModal xs={12}>
                        <Box sx={[styles.footerModal, { justifyContent: 'center', mt: 5 }]}>
                            <ButtonCustom child={'戻る'} onClick={() => closeRemindModal()} />
                            <Box sx={{ flex: '1 1 auto' }} />
                            <ButtonCustom isSubmit child={'送信する'} onClick={sendRemindMail} disabled={isLoading}/>
                        </Box>
                    </GridModal>
                </Box>
            </Modal>

            <ModalConfirm
                openModalConfirm={openModalConfirm}
                isLoading={isLoading}
                closeModalConfirm={closeModalConfirm}
                onConfirm={() => {
                    if (action === 'CANCEL') {
                        handleScheduleCopy()
                    } else if (action === 'DELETE') {
                        handleScheduleDelete()
                    }
                }}
                title={titleModalConfirm}
                message={messageModalConfirm}
                btnRight={action === 'CANCEL' || action === 'DELETE' ? 'はい' : undefined}
            />
        </Box>
    )
}

const CopyComponent = () => {
    return (
        <>
            <ContentCopyRoundedIcon sx={styles.boxIcon} />
            <Box sx={styles.actionText}>コピー</Box>
        </>
    )
}

const EditComponent = ({ scheduleId }: any) => {
    return (
        <Link
            style={{ textDecoration: 'none', color: '#000000', display: 'flex' }}
            to={`/adjustment/step1/${scheduleId}`}
        >
            <SvgIconEdit sx={styles.boxIcon} />
            <Box sx={styles.actionText}>修正</Box>
        </Link>
    )
}

const CancelComponent = () => {
    return (
        <>
            <DoNotDisturbIcon sx={styles.boxIcon} />
            <Box sx={styles.actionText}>キャンセル</Box>
        </>
    )
}

const DeleteComponent = () => {
    return (
        <>
            <DeleteOutlinedIcon sx={styles.boxIcon} />
            <Box sx={styles.actionText}>削除</Box>
        </>
    )
}

const OrderComponent = ({ order, orderThis }: any) => {
    return (
        <Box
            sx={{
                transform: order === Order.DESC ? 'rotate(180deg)' : 'rotate(0)',
            }}
        >
            <ChevronDownIcon stroke={orderThis ? '#000' : '#A0AEC0'} />
        </Box>
    )
}

export default TopDataTable

const styles = {
    container: { width: '100%', display: 'table', tableLayout: 'fixed' },
    table: {
        marginBottom: 0,
        th: {
            ...tableHeaderText,
            ...tableRowAlign,
            borderBottom: '1px solid #666666',
        },
        tr: {
            verticalAlign: 'top',
        },
        td: {
            ...tableBodyText,
            ...tableRowAlign,
            borderBottom: '1px solid #56577A',
        },
    },
    overlay: {
        cursor: 'pointer',
    },
    overlayItem: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: '5px',
    },
    boxIcon: {
        marginRight: '10px',
        fontSize: '18px',
    },
    modalBoxWrapper: {
        overflow: 'auto',
        maxHeight: '80vh',
        p: { xs: '20px', sm: '30px', md: 5 },
        borderRadius: '16px',
        width: '700px',
        bgcolor: '#FFFFFF',
        opacity: 1,
        margin: 'auto',
        border: 'none',
        '@media (max-width: 768px)': {
            width: '90%',
        },
    },
    footerModal: { display: 'flex', flexDirection: 'row' },
    buttonFooterModal: {
        border: '1px solid #C4C4C4',
        borderRadius: '12px',
        color: '#000000',
        fontWeight: 'bold',
        padding: '10px 20px',
    },
    cancelBadge: {
        border: '1px solid red',
        borderRadius: '3px',
        color: 'red',
        padding: '2px',
        fontSize: '12px',
        whiteSpace: 'nowrap',
        ml: '4px',
    },
    actionText: {
        whiteSpace: 'nowrap',
        fontSize: '15px',
        fontWeight: '500',
        lineHeight: '21px',
        letterSpacing: '0em',
        textAlign: 'left',
    },
    pagination: {
        '& ul': {
            justifyContent: 'end',
        },
        '& li': {
            width: '36px',
            height: '36px',
            mr: '5px',
        },
        'button:hover': {
            border: 'none',
            bgcolor: '#FFFF00 !important',
        },
        '.Mui-selected': {
            border: 'none',
            color: '#000',
            bgcolor: '#FFFF00 !important',
        },
        button: {
            width: '100%',
            height: '100%',
            border: '1px solid #666666',
            borderRadius: '50%',
            color: '#000000',
        },
        mt: 3,
    },
    externalUrl: {
        whiteSpace: 'nowrap',
        border: '1px solid',
        paddingLeft: '5px',
        borderRadius: '4px',
        height: '26px',
        maxWidth: '204px',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    externalUrlIcon: {
        fontSize: '18px',
        color: '#000000',
    },
    subjectCell: {
        minWidth: '240px',
    }
}
